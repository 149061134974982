import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { format, parseISO } from 'date-fns';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formats: PropTypes.string,
};

export default function RHFDatePicker({ name, label, formats = 'yyyy-MM-dd', ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        // Parse the incoming value into a Date object
        const dateValue = field.value ? parseISO(field.value) : null;
        // Format the date into the desired format
        const formattedDate = dateValue ? format(dateValue, formats) : '';

        return (
          <TextField
            {...field}
            fullWidth
            label={label}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!error}
            helperText={error?.message}
            {...other}
          />
        );
      }}
    />
  );
}
