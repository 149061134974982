import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >
        <path d="M1940 3621 c14 -27 64 -61 88 -61 11 0 28 -12 37 -27 l18 -28 -27 24
c-14 14 -29 21 -33 17 -4 -4 11 -23 33 -44 l41 -37 238 4 c145 2 240 -1 244
-6 3 -6 37 -13 75 -16 41 -3 63 -1 55 4 -8 5 -9 9 -4 9 14 0 37 -16 33 -23 -6
-10 52 -48 63 -41 8 4 9 3 5 -4 -4 -7 -4 -12 1 -12 4 0 19 -16 32 -35 13 -19
28 -32 34 -28 6 3 7 1 3 -6 -4 -6 -1 -23 8 -36 9 -13 18 -49 21 -79 12 -132
14 -170 6 -151 -7 17 -11 13 -28 -27 -25 -54 -87 -119 -141 -147 -42 -21 -166
-46 -183 -36 -5 4 -9 0 -9 -7 0 -8 -3 -9 -8 -3 -4 6 -12 11 -19 12 -9 2 -13
-32 -15 -122 -2 -69 -2 -125 0 -125 2 0 13 5 25 11 18 8 17 5 -5 -15 -33 -30
-36 -42 -7 -24 12 7 31 19 43 25 12 8 15 13 7 13 -8 0 -3 10 13 25 20 18 32
23 44 16 11 -6 27 -3 51 10 20 10 42 19 49 19 8 0 26 6 40 14 15 7 47 24 72
36 52 27 99 61 99 74 1 4 -6 5 -15 2 -14 -6 -15 -4 -4 9 10 12 15 13 20 4 10
-15 33 -1 26 16 -2 7 2 23 11 34 8 12 12 25 8 29 -3 3 -10 0 -15 -7 -6 -10 -8
-7 -7 10 1 15 8 25 17 25 19 0 20 -31 2 -66 -13 -24 -12 -24 12 3 14 15 26 37
26 48 0 11 4 18 9 14 5 -3 20 21 33 52 19 48 23 77 23 163 0 104 -19 205 -36
194 -5 -3 -9 -1 -9 3 0 5 -10 13 -22 18 -14 6 -24 20 -26 35 l-2 25 15 -26 c8
-15 21 -29 30 -33 17 -6 21 9 5 19 -6 4 -16 21 -24 38 -23 54 -58 84 -97 83
-29 -1 -34 2 -36 22 l-1 24 24 -23 c13 -12 27 -20 30 -16 8 8 -46 55 -96 83
-32 18 -39 19 -55 8 -11 -8 -30 -14 -44 -13 -21 1 -19 3 13 13 44 14 40 19
-25 37 -50 14 -778 23 -744 9 18 -7 18 -9 3 -15 -11 -4 -24 -1 -33 9 -22 22
-28 20 -15 -3z m797 -17 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-30 -30 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-24 -111 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m308 -35 c11 -21 11 -22
-4 -9 -10 7 -17 17 -17 22 0 15 9 10 21 -13z m-196 2 c3 -5 2 -10 -4 -10 -5 0
-13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m79 -44 c-3 -8 -12 -17 -20
-20 -18 -7 -18 -1 2 18 19 20 25 20 18 2z m-1 -41 c-3 -9 -8 -14 -10 -11 -3 3
-2 9 2 15 9 16 15 13 8 -4z m113 -23 c-4 -3 -11 0 -16 8 -15 24 -11 33 6 15 9
-9 13 -19 10 -23z m-32 -18 c9 -24 7 -27 -9 -10 -17 17 -19 26 -6 26 5 0 12
-7 15 -16z m-36 -16 c15 -15 16 -45 2 -53 -5 -3 -7 0 -4 9 3 8 -4 24 -15 35
-13 13 -16 21 -8 21 7 0 18 -5 25 -12z m39 -70 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z m103 12 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m17 -57 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10
2 -26 0 -35z m-150 0 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m26 -16 c2 -20 0 -34 -5 -31 -4 3 -8 21 -8 41 0 45 10 38 13 -10z m107 -26 c0
-32 -6 -24 -14 19 -4 25 -3 31 4 20 5 -8 10 -26 10 -39z m-123 -73 c-2 -13 -4
-3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m114 -80 c-7 -18 -9 -14 -9 22 0
36 2 40 9 23 4 -13 5 -33 0 -45z m-177 -31 c-3 -8 -12 -18 -20 -21 -15 -6 -11
7 11 30 13 15 17 11 9 -9z m41 -117 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m-5 -54 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10
-9 4 5 14 24 23 24 12z m-70 -26 c8 -5 11 -12 8 -16 -4 -3 -14 1 -23 10 -18
17 -9 21 15 6z m-141 -73 c-28 -21 -59 -36 -59 -29 0 9 37 30 54 31 5 1 7 0 5
-2z"/>
<path d="M2100 2779 c0 -435 1 -461 18 -455 9 4 54 28 99 54 l83 47 0 313 0
312 166 0 c94 0 174 4 185 10 53 29 61 121 12 160 -24 19 -39 20 -294 20
l-269 0 0 -461z"/>
<path d="M2590 2614 c0 -14 3 -15 18 -3 9 7 19 15 21 16 2 2 -5 3 -17 3 -14 0
-22 -6 -22 -16z"/>
<path d="M1365 2082 c-3 -9 -1 -19 6 -22 8 -6 8 -9 0 -14 -14 -9 -12 -226 2
-243 13 -17 64 -16 74 1 4 7 19 17 33 21 24 7 24 7 5 -9 -23 -18 -13 -21 16
-5 11 5 16 15 13 21 -4 6 -3 8 3 5 29 -18 66 94 51 151 -12 44 -27 64 -41 55
-6 -3 -7 -1 -3 5 10 16 -41 39 -101 44 -40 4 -53 2 -58 -10z m55 -6 c0 -2 -7
-7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m63 -13 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m23 -38 c20 -20 21 -28 15 -90 -8 -71 -20
-89 -68 -102 -48 -11 -53 -1 -53 111 0 74 4 106 13 109 23 9 72 -6 93 -28z
m51 -82 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-20 5 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2 -80 c-10 -13 -14 -16 -11
-8 3 8 9 24 13 35 4 11 8 14 11 7 2 -6 -4 -22 -13 -34z m-102 -55 c-7 -2 -21
-2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M1653 2093 c-10 -3 -12 -42 -11 -151 l2 -147 6 125 6 125 4 -123 c7
-162 24 -160 26 3 1 68 -3 122 -7 119 -5 -3 -9 0 -9 5 0 6 4 11 9 11 12 0 7
33 -5 36 -5 1 -15 0 -21 -3z"/>
<path d="M1830 2087 c-47 -16 -78 -73 -79 -143 0 -29 4 -56 10 -59 5 -4 7 -10
4 -15 -3 -4 11 -22 30 -39 29 -25 43 -30 80 -29 31 2 44 6 41 15 -3 7 1 10 10
6 11 -4 14 6 13 48 0 43 -2 49 -9 32 -13 -33 -20 -28 -10 7 7 23 5 30 -5 30
-9 0 -13 -14 -12 -47 1 -62 -17 -74 -67 -45 -20 12 -36 17 -36 12 0 -5 9 -14
20 -20 11 -6 20 -15 20 -20 0 -6 -11 -1 -23 10 -21 18 -24 29 -22 93 1 79 14
113 48 128 20 8 19 9 -6 5 -19 -2 -25 0 -19 6 7 7 30 7 66 1 31 -6 56 -7 56
-4 -1 32 -58 47 -110 28z m67 -3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-107 -184 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9
0 16 -4 16 -10z"/>
<path d="M2120 2080 c-10 -18 -8 -20 20 -20 67 0 70 -7 68 -143 l-2 -122 -6
125 -6 125 0 -125 c1 -118 2 -125 21 -125 19 0 20 7 20 128 l0 127 33 0 c28 0
32 3 32 28 0 21 -2 24 -10 12 -8 -12 -11 -13 -19 -1 -5 7 -11 9 -14 4 -4 -6
-25 -7 -54 -2 -69 11 -71 11 -83 -11z m90 -10 c8 -5 11 -10 5 -10 -5 0 -17 5
-25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z"/>
<path d="M2469 2079 c0 -22 -1 -22 -8 -4 -8 19 -8 19 -18 0 -11 -22 -32 -84
-34 -100 0 -5 -4 -14 -9 -20 -11 -13 -60 -144 -60 -159 1 -6 9 5 20 24 21 38
26 36 10 -4 -13 -36 5 -33 20 3 10 26 17 30 63 33 65 5 74 2 81 -27 5 -20 43
-41 54 -30 2 2 -7 30 -21 62 -14 32 -40 99 -57 148 -37 103 -40 107 -41 74z
m21 -38 c0 -10 -39 -11 -45 -2 -2 5 7 9 20 9 14 0 25 -3 25 -7z m-53 -33 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m36 -10 c4 -10 8 -9 16 2 9
13 11 12 12 -5 1 -11 2 -26 2 -32 -1 -7 3 -13 8 -13 6 0 6 -8 0 -18 -7 -13 -6
-23 1 -30 12 -12 0 -17 -25 -13 -5 1 -6 -3 -3 -8 4 -5 2 -12 -3 -15 -5 -3 -11
1 -15 9 -3 8 -10 15 -16 15 -6 0 -8 -5 -4 -11 5 -8 1 -8 -13 0 -11 6 -23 8
-27 4 -3 -3 3 11 13 33 19 39 19 61 0 34 -9 -12 -10 -12 -5 3 3 10 6 24 6 30
0 7 4 6 10 -3 8 -13 11 -11 16 8 7 24 20 29 27 10z m-77 -100 c-18 -26 -22
-17 -5 14 6 11 13 17 16 15 3 -3 -2 -16 -11 -29z m163 -68 c0 -10 -3 -8 -9 5
-12 27 -12 43 0 25 5 -8 9 -22 9 -30z"/>
<path d="M2487 1973 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2641 1950 c1 -85 5 -147 10 -144 5 3 9 0 9 -5 0 -13 97 -13 125 0
15 7 8 8 -27 6 -56 -3 -50 10 7 16 36 3 35 4 -12 5 -36 1 -53 -2 -54 -10 0 -7
-4 -4 -9 7 -5 11 -9 75 -9 141 -1 120 -5 134 -37 134 -2 0 -4 -67 -3 -150z
m26 -43 c-2 -23 -3 -1 -3 48 0 50 1 68 3 42 2 -26 2 -67 0 -90z"/>
<path d="M2850 1944 l0 -155 28 3 c25 3 27 7 30 49 3 47 3 47 45 53 60 10 88
37 95 91 8 79 -38 115 -150 115 l-48 0 0 -156z m126 98 c41 -27 15 -102 -35
-102 -28 0 -29 2 -33 55 l-3 55 30 0 c17 0 35 -4 41 -8z"/>
<path d="M3096 1953 c-31 -82 -56 -151 -56 -155 0 -5 11 -8 25 -8 17 0 29 9
40 30 14 27 20 30 66 30 47 0 52 -2 62 -30 9 -23 17 -30 39 -30 15 0 28 5 28
10 0 6 -25 75 -57 155 -42 108 -61 145 -73 145 -13 0 -32 -38 -74 -147z m91
10 c6 -21 15 -46 18 -55 6 -15 1 -18 -34 -18 -23 0 -41 3 -41 6 0 13 35 104
40 104 3 0 10 -17 17 -37z"/>
<path d="M3298 2068 c72 -120 82 -146 82 -212 l0 -66 30 0 30 0 0 70 c0 66 3
77 45 149 25 43 45 81 45 84 0 4 -11 7 -25 7 -19 0 -31 -12 -57 -60 -18 -33
-36 -60 -39 -60 -3 0 -21 27 -38 60 -28 53 -36 60 -62 60 l-31 0 20 -32z"/>
<path d="M2019 2068 c-2 -13 -2 -80 1 -150 4 -95 8 -125 17 -120 16 10 20 268
4 265 -19 -5 -12 15 7 20 13 4 13 5 -4 6 -15 1 -23 -6 -25 -21z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
